export const dateCompare = (aTime='',bTime='') => {
    //判断aTime,bTime是否有值，只有都有值才能比较
    if(!aTime||!bTime){
        return false
    }
    //将两个时间转成时间戳数字
    const aTimeNum = new Date(aTime).getTime()
    const bTimeNum = new Date(bTime).getTime()
    //判断两个时间大小，‘1’则aTime时间靠后，’0‘则两个时间相同，‘-1’则bTime时间靠后
    if (aTimeNum-bTimeNum>0){
        return '1'
    }else if (aTimeNum===bTimeNum){
        return '0'
    }else {
        return '-1'
    }
}