
// import EditEnquiry from "@/views/Claim/EnquiryProgram/EditEnquiryProgram";
import { defineComponent, ref, reactive, nextTick } from 'vue';
import { message } from 'ant-design-vue';
import moment, { Moment } from 'moment';
import {
    getSetupInformationReview,
    deleteSetupLst,
    setupLstView,
    getBuListNew,
    getAreasDate,
    addSetupLst,
    editSetupLst,
    getRegionLst,
} from '@/API/checking/claimDelayRuleSetup';
import { AFCandLCApproveTableData } from '@/views/Payment/Mgmt/types';
import { dateCompare } from '@/utils/claim/dateCompare';
const tableWidth = window.innerWidth;
const columns = reactive([
    {
        title: 'Region',
        align: 'left',
        dataIndex: 'region',
        width: 100,
        slots: { customRender: 'region' },
    },
    {
        title: 'Province',
        align: 'center',
        dataIndex: 'province',
        width: 120,
        slots: { customRender: 'province' },
    },
    {
        title: 'City',
        align: 'center',
        dataIndex: 'city',
        width: 120,
        slots: { customRender: 'city' },
    },
    {
        title: 'Dealer Code',
        align: 'center',
        dataIndex: 'dealerCode',
        width: 120,
        slots: { customRender: 'dealerCode' },
    },
    {
        title: 'Dealer Name',
        align: 'center',
        dataIndex: 'dealerName',
        width: 180,
        slots: { customRender: 'dealerName' },
    },
    {
        title: 'Program Code',
        align: 'center',
        dataIndex: 'programCode',
        width: 150,
        slots: { customRender: 'programCode' },
    },
    {
        title: 'Business Unit',
        align: 'center',
        dataIndex: 'businessUnit',
        width: 120,
        slots: { customRender: 'businessUnit' },
    },
    {
        title: 'Status',
        align: 'center',
        dataIndex: 'timeStatus',
        width: 100,
        slots: { customRender: 'timeStatus' },
    },
    {
        title: 'Delay Deadline',
        align: 'center',
        dataIndex: 'delayDeadline',
        width: 150,
        slots: { customRender: 'delayDeadline' },
    },
    {
        title: 'Operation',
        dataIndex: 'checkResult',
        width: 130,
        slots: { customRender: 'operation' },
    },
]);
const columnsDealer = reactive([
    {
        title: 'Dealer Code',
        align: 'center',
        dataIndex: 'dealerCode',
        width: 120,
    },
    {
        title: 'Dealer Name',
        align: 'center',
        dataIndex: 'dealerNameEn',
        width: 120,
    },
]);
const columnsProgram = reactive([
    {
        title: 'Program Code',
        align: 'center',
        dataIndex: 'programCode',
        width: 120,
    },
    {
        title: 'Program Name',
        align: 'center',
        dataIndex: 'programNameEn',
        width: 120,
    },
]);
interface SearchDataType {
    dealerCode: null;
    dealerNameCn: null;
    dealerNameEn: null;
    region: null;
    vinNos: Array<string>;
    checkResult: Array<string>;
    checkingStatus: Array<string>;
    appeal: null;
    autoReturned: null;
}
export default defineComponent({
    components: {},
    setup() {
        const tableHeight = ref();
        const searchCon = ref();
        const queryListData = ref<any[]>([]);
        const formLst = ref<any>({
            businessUnit: null,
            region: null,
            programCode: null,
            province: null,
            city: null,
            dealerCode: null,
            deadlinDate: null,
            comments: null,
        });
        const nowTiem = moment().format('YYYY/MM/DD');
        const nowItemData = ref<any>({});
        const nowDealerLstItem = ref<any>({
            dealers: [],
            programs: [],
            status: '',
        });
        const areasArr = ref<any>([]);
        const nowCityLst = ref<any>([]);
        const businessUnitSelect = ref<any>([]);
        const editDeadLine = ref<any>('');
        const codeModalInf = ref<any>({
            isCover: false,
            codeModalTit: '',
            codeModalLstTit: '',
        });
        const textContentNo = ref<string>('');
        const queryParams = ref<any>({
            dealerCode: [],
            porgramCode: [],
        });
        const queryParams2 = ref<any>({
            dealerCode: '',
            porgramCode: '',
        });
        const commentsLastValue = ref<any>('');
        const regionLst = ref<any>('');
        //筛选条件，各个信息框的内容
        const queryListParams = reactive({
            selectProgram: '',
            checkingResult: [],
        });
        nextTick(() => {
            (document.getElementsByClassName(
                'table-box'
            )[0] as any).style.height =
                window.innerHeight - 360 - searchCon.value.scrollHeight + 'px';
            const height = (document.getElementsByClassName(
                'table-box'
            )[0] as any).style.height;
            const heightNum = parseInt(height.substr(0, height.indexOf('px')));
            // tableHeight.value = heightNum - 110;
            tableHeight.value = heightNum - 60;
        });
        const rowSelection = reactive<{
            selectedRowKeys: [];
            selectedRowData: AFCandLCApproveTableData[];
            onChange: Function;
            columnWidth: string;
        }>({
            onChange: (
                selectedRowKeys: [],
                selectedRows: AFCandLCApproveTableData[]
            ) => {
                rowSelection.selectedRowKeys = selectedRowKeys;
                rowSelection.selectedRowData = selectedRows;
            },
            selectedRowKeys: [],
            selectedRowData: [],
            columnWidth: '40px',
        });
        const doGetListData = () => {
            getSetupInformationReview().then((res) => {
                // console.log(286,res)
                // const dataLst = ref<any>([])
                res.forEach((i: any, ind: any) => {
                    // console.log(668,i.delayDeadline,nowTiem,dateCompare(i.delayDeadline,nowTiem))
                    i.timeStatus =
                        dateCompare(i.delayDeadline, nowTiem) === '1' ||
                        dateCompare(i.delayDeadline, nowTiem) === '0'
                            ? 'Active'
                            : 'Inactive';
                    i.timeEdit =
                        dateCompare(i.delayDeadline, nowTiem) === '1' ||
                        dateCompare(i.delayDeadline, nowTiem) === '0'
                            ? true
                            : false;
                });
                queryListData.value = res;
            });
        };
        doGetListData();
        const doGetSelect = () => {
            getBuListNew().then((res) => {
                businessUnitSelect.value = res.filter((item: any) => {
                    return item.parentId === null;
                });
            });
            getAreasDate().then((res) => {
                console.log(576, res);
                areasArr.value = res;
            });
        };
        doGetSelect();
        const regionAble = ref<any>(false);
        const cityAble = ref<any>(false);
        const provinceAble = ref<any>(true);
        const viewCove = ref<any>(false);
        const editCove = ref<any>(false);
        const dealerCove = ref<any>(false);
        const judgeForm = () => {
            if (!formLst.value.businessUnit) {
                formLst.value.region = null;
                window.setTimeout(() => {
                    regionAble.value = false;
                }, 100);
            } else {
                if (formLst.value.dealerCode) {
                    regionAble.value = false;
                } else {
                    regionAble.value = true;
                }
            }
        };
        const judgeFormProvince = () => {
            if (!formLst.value.province) {
                formLst.value.city = null;
                window.setTimeout(() => {
                    cityAble.value = false;
                }, 100);
            } else {
                areasArr.value.forEach((i: any) => {
                    if (formLst.value.province === i.provinceEn) {
                        nowCityLst.value = i.cityList;
                    }
                });
                cityAble.value = true;
            }
        };
        const judgeFormDelerCode = () => {
            if (formLst.value.dealerCode) {
                formLst.value.city = null;
                formLst.value.province = null;
                formLst.value.region = null;
                window.setTimeout(() => {
                    cityAble.value = false;
                    provinceAble.value = false;
                    regionAble.value = false;
                }, 100);
            } else {
                console.log(formLst.value.dealerCode);
                cityAble.value = false;
                provinceAble.value = true;
                regionAble.value = formLst.value.businessUnit ? true : false;
            }
        };
        const batchQuery = reactive({
            batchQueryTitle: '',
            batchQueryModal: false,
            batchQueryListTitle: '',
        });
        //program code & dealer code
        //显示的VIN数组
        const textNoArray = ref<any>([]);
        const cleanEmptyEleArray = (array: Array<string>) => {
            if (array.length > 1) {
                const tempArray = array.filter((item) => item !== '');
                tempArray.length || tempArray.push('');
                console.log(830, tempArray);
                return tempArray;
            } else {
                console.log(833, array);
                return array;
            }
        };
        const searchData = reactive<SearchDataType>({
            dealerCode: null,
            dealerNameCn: null,
            dealerNameEn: null,
            region: null,
            vinNos: [''],
            checkResult: [],
            checkingStatus: [],
            appeal: null,
            autoReturned: null,
        });
        //空占位
        const batchPlaceholder = ref(['', '', '', '', '', '']);
        // 设置批量查询modal 的 占位div
        const setBatchPlaceholder = () => {
            if (textNoArray.value.length) {
                batchPlaceholder.value = [];
            } else {
                batchPlaceholder.value = ['', '', '', '', '', ''];
            }
        };
        // 设置批量查询modal 的 textNoArray
        const setBatchQueryTextNoArray = () => {
            console.log(863, textNoArray.value);
            if (codeModalInf.value.codeType === 'dealerCode') {
                textNoArray.value =
                    cleanEmptyEleArray(queryParams.value.dealerCode)[0] === ''
                        ? []
                        : cleanEmptyEleArray(queryParams.value.dealerCode);
            } else {
                textNoArray.value =
                    cleanEmptyEleArray(queryParams.value.porgramCode)[0] === ''
                        ? []
                        : cleanEmptyEleArray(queryParams.value.porgramCode);
            }
        };
        //输入框，用于控制焦点
        const input = ref();
        // 批量查询按钮事件
        const batchQueryEvent = () => {
            batchQuery.batchQueryModal = true;
            batchQuery.batchQueryTitle = 'VIN No. Query';
            batchQuery.batchQueryListTitle = 'VIN No. List';
            textNoArray.value =
                cleanEmptyEleArray(searchData.vinNos)[0] === ''
                    ? []
                    : cleanEmptyEleArray(searchData.vinNos);
            setBatchPlaceholder();
            const dom = document.getElementsByClassName('list-content')[0];
            nextTick(() => {
                dom && dom.scrollTo(0, textNoArray.value.length * 36);
                input.value.focus();
            });
        };
        const handleDelete = (e: any) => {
            console.log(506, e.id);
            deleteSetupLst({ id: e.id }).then((res) => {
                console.log(508, res.code);
                if (res.code === '0') {
                    message.success('Success!');
                } else if (res.code === '500') {
                    message.error(res.message);
                }
                doGetListData();
            });
        };
        const handlView = (e: any) => {
            setupLstView({ id: e.id }).then((res) => {
                console.log(567, res);
                nowItemData.value = res;
                viewCove.value = true;
            });
        };
        const handlEdit = (e: any) => {
            setupLstView({ id: e.id }).then((res) => {
                console.log(567, res);
                nowItemData.value = res;
                editDeadLine.value = res ? res.delayDeadline : '';
                editCove.value = true;
            });
        };

        const handleCancel = () => {
            viewCove.value = false;
            editCove.value = false;
            dealerCove.value = false;
            nowItemData.value = {};
        };
        const handleDealerCancel = () => {
            dealerCove.value = false;
            nowDealerLstItem.value = {
                dealers: [],
                programs: [],
                status: '',
            };
        };
        const handleDealerMore = (e: any) => {
            const params = ref<any>({});
            params.value.dealers = nowItemData.value.dealers;
            params.value.programs = nowItemData.value.programs;
            params.value.status = e;
            if (e === 'dealers') {
                params.value.statusText = 'Dealer';
            } else if (e === 'programs') {
                params.value.statusText = 'Program';
            }
            nowDealerLstItem.value = params.value;
            dealerCove.value = true;
        };
        const handleEditOk = () => {
            const params = {
                businessUnit: nowItemData.value.businessUnit,
                city: nowItemData.value.city,
                comments: nowItemData.value.comments,
                dealers: nowItemData.value.dealers,
                delayDeadline: editDeadLine.value,
                programs: nowItemData.value.programs,
                province: nowItemData.value.province,
                region: nowItemData.value.region,
                id: nowItemData.value.id,
            };
            editSetupLst(params).then((res) => {
                if (res.code === '0') {
                    message.success('Success!');
                    handleCancel();
                    doGetListData();
                } else {
                    message.error(res.message);
                }
            });
        };
        const handleAdd = () => {
            console.log(formLst.value);
            if (formLst.value.businessUnit) {
                if (formLst.value.deadlinDate) {
                    if (formLst.value.comments) {
                        const params = {
                            businessUnit: formLst.value.businessUnit,
                            city: formLst.value.city,
                            comments: formLst.value.comments,
                            dealers: queryParams.value.dealerCode,
                            delayDeadline: formLst.value.deadlinDate,
                            programs: queryParams.value.porgramCode,
                            province: formLst.value.province,
                            region: formLst.value.region,
                        };
                        addSetupLst(params).then((res) => {
                            if (res.code === '0') {
                                doGetListData();
                                message.success('Success!');
                                formLst.value = {
                                    businessUnit: null,
                                    region: null,
                                    programCode: null,
                                    province: null,
                                    city: null,
                                    dealerCode: null,
                                    deadlinDate: null,
                                    comments: null,
                                };
                            } else {
                                doGetListData();
                                message.error(res.message);
                            }
                        });
                    } else {
                        message.error('Please add delay comments');
                    }
                } else {
                    message.error('Please choose delay deadline');
                }
            } else {
                message.error('Please choose Business Unit');
            }
        };
        const watchComments = (e: any) => {
            console.log(e.target.value.length);
            if (e.target.value && e.target.value.length > 200) {
                message.error('Exceed Limited Word.');
                formLst.value.comments = commentsLastValue.value;
            } else {
                commentsLastValue.value = e.target.value;
            }
        };
        const disabledDate = (current: Moment) => {
            console.log(current == moment().startOf('day'));
            return current && current < moment().endOf('day');
        };
        const handleDealerCodeBtn = (codeType: any) => {
            console.log(codeType);
            if (codeType === 'dealerCode') {
                codeModalInf.value = {
                    isCover: true,
                    codeType: 'dealerCode',
                    codeModalTit: 'Dealer Code',
                    codeModalLstTit: 'Dealer Code',
                };
            } else if (codeType === 'programCode') {
                codeModalInf.value = {
                    isCover: true,
                    codeType: 'programCode',
                    codeModalTit: 'Program Code',
                    codeModalLstTit: 'Program Code',
                };
            }
            console.log(1045, queryParams.value);
            setBatchQueryTextNoArray();
            setBatchPlaceholder();
            const dom = document.getElementsByClassName('list-content')[0];
            nextTick(() => {
                dom && dom.scrollTo(0, textNoArray.value.length * 36);
                input.value.focus();
            });
        };
        const closeDealerCodeCover = () => {
            console.log(queryParams.value.dealerCode);
            formLst.value.dealerCode = cleanEmptyEleArray(
                queryParams.value.dealerCode
            )[0];
            formLst.value.programCode = cleanEmptyEleArray(
                queryParams.value.porgramCode
            )[0];
            if (codeModalInf.value.codeType === 'dealerCode') {
                judgeFormDelerCode();
            }
        };
        const textChangEvent = () => {
            if (textContentNo.value.length < 5) {
                message.error('请从Excel中粘贴到文本框');
                textContentNo.value = '';
                return;
            }
            console.log(1049, textNoArray.value);
            textNoArray.value = textNoArray.value.concat(
                textContentNo.value.replace(/\r|\n|\s/g, ',').split(',')
            );
            console.log(
                1050,
                textNoArray.value.concat(
                    textContentNo.value.replace(/\r|\n|\s/g, ',').split(',')
                )
            );
            const dom = document.getElementsByClassName('list-content')[0];
            textContentNo.value = '';
            if (codeModalInf.value.codeType === 'dealerCode') {
                queryParams.value.dealerCode = [];
                queryParams.value.dealerCode = queryParams.value.dealerCode.concat(
                    textNoArray.value
                );
            } else {
                queryParams.value.porgramCode = [];
                queryParams.value.porgramCode = queryParams.value.porgramCode.concat(
                    textNoArray.value
                );
            }
            setBatchPlaceholder();
            nextTick(() => {
                dom.scrollTo(0, dom.scrollHeight + 40);
                input.value.focus();
            });
        };
        // 清除批量查询
        const clearBatchQuery = () => {
            if (codeModalInf.value.codeType === 'dealerCode') {
                queryParams.value.dealerCode = [''];
            } else {
                queryParams.value.porgramCode = [''];
            }
            setBatchQueryTextNoArray();
            setBatchPlaceholder();
            input.value.focus();
        };
        const dogetRegion = () => {
            getRegionLst({ bu: formLst.value.businessUnit || '' }).then(
                (res) => {
                    formLst.value.region = null;
                    console.log(1151, res);
                    regionLst.value = res;
                }
            );
        };

        return {
            dogetRegion,
            regionLst,
            commentsLastValue,
            queryParams2,
            clearBatchQuery,
            queryParams,
            textContentNo,
            textChangEvent,
            setBatchQueryTextNoArray,
            closeDealerCodeCover,
            codeModalInf,
            handleDealerCodeBtn,
            handleEditOk,
            editDeadLine,
            handlEdit,
            editCove,
            disabledDate,
            nowCityLst,
            judgeFormProvince,
            watchComments,
            judgeFormDelerCode,
            provinceAble,
            areasArr,
            businessUnitSelect,
            doGetSelect,
            handleAdd,
            nowDealerLstItem,
            columnsProgram,
            columnsDealer,
            handleDealerMore,
            handleDealerCancel,
            dealerCove,
            nowItemData,
            handlView,
            handleCancel,
            viewCove,
            handleDelete,
            nowTiem,
            batchPlaceholder,
            input,
            setBatchPlaceholder,
            searchData,
            cleanEmptyEleArray,
            textNoArray,
            batchQuery,
            batchQueryEvent,
            cityAble,
            regionAble,
            judgeForm,
            formLst,
            doGetListData,
            rowSelection,
            searchCon,
            tableHeight,
            queryListData,
            queryListParams,
            tableWidth,
            columns,
        };
    },
});
